.button, button{
  @include media(mobile) {
    width: 100%;
    margin-bottom: 15px;
    height: 42px;
    line-height: 41px;
  }
  @include media(tablet) {
    height: 55px;
    line-height: 50px;
  }
  text-align: center;
  position: relative;
  display: inline-block;
  height: 55px;
  line-height: 50px;
  font-family: $tisaot;
  font-style: italic;
  font-size: 17px;
  font-weight: bold;
  padding: 0 30px;
  color: $white;
  border-radius: 1px;
  outline: none;
  border: none;
  transition: $fast;
  cursor: pointer;
  background-color: $blue;
  &:hover{
    background-color: $blue_light;
    color: $white;
    box-shadow: 0 0 50px rgba(0,0,0,0.12);
    .fa-arrow-right {
      margin-left: 10px; 
    }
  }
  .fa-arrow-right{
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    transition: $fast;
  }
  &_white{
    background-color: $white;
    color: $blue;
    &:hover{
      background-color: $white;
      color: $blue_light;
    }
  }
}

.button_link{
  font-family: $tisaot;
  color: $blue;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  transition: $fast;
  text-decoration: none;
  letter-spacing: -0.5px;
  &:hover{
    text-decoration: none!important;
    // border-bottom: 1px solid $blue;
    .fa-arrow-right {
      margin-left: 10px; 
      color: $blue_dark;
    }
  }
  .fa-arrow-right{
    position: relative;
    display: inline-block;
    color: $blue;
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    transition: $fast;
  }
}