.page_content{
  position: relative;
  @include media(mobile) {
    margin-left: 0;
    padding: 150px 30px 50px;
    max-width: 100vw;
    min-height: calc(100vh + 135px);
  }
  @include media(tablet) {
    margin-left: calc(55% - 155px);
    width: calc(45% + 140px);
    padding-top: 150px;
    padding-bottom: 150px;
    max-width: 750px;
    // min-height: 100vw;
  }
  @include media(large) {
    margin-left: calc(50% - 155px);
  }
}

.background_container{
  @include media(mobile) {display: none;}
  @include media(tablet) {width: calc(55% - 155px);background-position: calc(100% - 5px) bottom;  }
  @include media(large) {width: calc(50% - 155px); background-position: calc(100% - 25px) bottom; }
  position: fixed;
  height: 100vh;
  z-index: 99;
  .background_image{
    position: absolute;
    width: 95%;
    left: 0;
    top: 10%;
    height: 90%;
    background-image: url('../img/bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    
  }
}
.contact_details{
  @include media(mobile) {
    position: relative;
    width: 100%;
  }
  @include media(tablet) {
    position: absolute; 
    height: 200px;
    padding-left: 10%;
    bottom: 0;
  }
  .name {
    @include media(mobile) {font-size: 30px; line-height: 30px; color: $blue;}
    @include media(tablet) {font-size: 50px; line-height: 60px; color: $white;}
    font-family: "Journal";
    text-shadow: 0 0 30px rgba(0,0,0,0.8);
  }
  .function {
    @include media(mobile) {font-size: 14px; line-height: 20px;}
    @include media(tablet) {font-size: 18px; line-height: 25px;}
    text-shadow: 0 0 30px rgba(0,0,0,0.8);
    font-size: 18px;
    font-family: $tisaot;
    font-style: italic;
    color: $white;
    margin-bottom: 15px;
  }
}



.quote{
  width: 100%;
  margin: 60px auto;
  text-align: center;
  .author{
    font-size: 13px;
    color: $txt_color_light;
  }
}

.block_note {
  @include media(mobile) {
    margin: 60px -15px 45px;
    padding: 30px 25px;
  }
  @include media(tablet) {
    margin: 60px 0 45px -30px;
    padding: 15px 30px 30px 30px;
  }
  background-color: $custom_dark;
  line-height: 30px;
  font-size: 16px;
}

.reading_suggestions{
  font-size: 14px;
  line-height: 31px;
  .fa-arrow-right{
    color: $blue;
    display: inline-block;
    position: relative;
    margin-right: 8px;
  }
  a{
    color: $txt_color!important;
    text-decoration: underline;
    transition: $fast;
    &:hover{
      text-decoration: none;
    }
  }
}


.mapcontainer{
  height: 500px;
  max-width: 750px;
}


.custom_list{
  list-style-type: none;
  margin: 10px 0;
  padding: 0;
  padding-left: 15px;
  li{
    line-height: 30px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: $txt_color;
    font-size: 15px;
    &::before{
      font-family: "FontAwesome";
      content: '\f061';
      float: left;
      color: $blue;
      margin-right: 8px;
      font-size: 14px;
      line-height: 30px;
    }
  }
}