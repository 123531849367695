.form_carrier{
  padding: 0;
  color: $txt_color;
  max-width: 600px;
  margin-left: 0;
}

.label{
  color: $txt_color;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 50px;
}

.field{
  width: 100%;
  margin: 5px 0;
  min-height: 40px;
  line-height: 30px;
  padding: 4px 10px;
  border-radius: 5px; 
  outline: none;
  border: 2px solid $custom;
  font-family: $font_sans;
  &:focus{
    border: 2px solid $grey_lighter;
  }
  &::placeholder{
    color: $grey_lighter;
    text-transform: uppercase;
    font-size: 14px;
  }
}
.checkbox{
  line-height: 30px;
  margin: 5px 0;
  line-height: 30px;
  // vertical-align: middle;
}