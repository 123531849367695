@include media(mobile) {}
@include media(tablet) {}
@include media(desktop) {}
@include media(large) {}

.helpborder {
	border: 1px solid red;
}
.grecaptcha-badge { visibility: hidden; }
.hidden { visibility: hidden; }
html {
	margin: 0px;
	padding: 0px;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
}
html.noscroll{
  overflow: hidden!important;
}
body {
	background-color: $custom!important;
	padding: 0px;
	margin: 0px;
	overflow: hidden;
	width: 100vw;
	// min-height: 100vh;
	font-family: $font_sans;
}
div {box-sizing: border-box;}
p {
	@include media(mobile) {line-height: 30px; 	font-size: 16px;}
	@include media(tablet) {line-height: 36px; 	font-size: 16px;}
	color: $txt_color;
	a {font-weight: bold;}
}
p.intro{
	@include media(mobile) {line-height: 30px; 	font-size: 19px;}
	@include media(tablet) {line-height: 36px; 	font-size: 22px;}
	margin-bottom: 42px;
	font-weight: 300;
	color: $txt_color_light;
}


a {color: $blue; transition: $fast;}
a:hover{color: $blue_dark; text-decoration: none;}
a.light{font-weight: normal!important;}

.right {
	float: right;
}
.left {
	float: left;
}
.align_right {
	text-align: right;
	justify-content: flex-end;
	&_md{
		 @include media(mobile) {
			 text-align: left!important;
			 justify-content: flex-start;
		 }
		 @include media(tablet) {
			 text-align: right;
		 	 justify-content: flex-end;
		 }
	}
}

.align_center {
	text-align: center;
	margin: 0 auto;
	justify-content: center;
	&_mob {
		@include media(mobile) {
			text-align: center;
			margin: 0 auto;
			justify-content: center;
		}
	}
}
.align_left {
	text-align: left;
	justify-content: flex-start;
}
.pm0, .mp0 {
	margin: 0!important;
	padding: 0!important;
}
.mobile {
	@include media(tablet) {display: none!important;}
}
.no_mobile {
  @include media(mobile) {display: none!important;}
}

.disable {
	display: none!important;
}
.no-float{
	float: none!important;
}
// ::-webkit-scrollbar {
// 	position: absolute;
//   width: 10px;
// }
// ::-webkit-scrollbar-track {
// 	position: absolute;
//   background: rgba(0,0,0,0.15);
// 	border-radius: 1px;
// }
// ::-webkit-scrollbar-thumb {
//   background: rgba(0,0,0,0.5);
// 	border-radius: 1px;
// 	&:hover {
// 	  background: #555;
// 	}
// }