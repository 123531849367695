@charset "UTF-8";
.animated {
  animation-duration: 0.75s;
  animation-fill-mode: both;
}
.wow {
  visibility: hidden;
  } 
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(5vh);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp, .slideInUp {
  animation-name: fadeInUp;
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-timing-function: ease;
  opacity: 0;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0); }
    40% {transform: translateY(-15px);}
    60% {transform: translateY(-7px);}
}

.bounce{
  animation: bounce 2s infinite;
}

$delay: 10;
@for $i from 1 through $delay {
    .d#{$i} {
        animation-delay: (0.3s * $i);
    }
}