.scroll_window{
  @include media(mobile) {  padding: 100px 0px 15px;}
  @include media(tablet) {  padding: 180px 0px 150px 15px;}
  height: calc(100vh - 15px);
  scroll-snap-align: center;
  scroll-snap-stop: always;
  position: relative;
}

main.scrollsnap{
  @include media(mobile) {scroll-snap-type: y proximity;}
  @include media(tablet) {scroll-snap-type: y mandatory;}
  display: block;
  position: relative;
  top: 0;
  right: 0;
	margin: 0px;
	padding: 0px;
	height: calc(100vh - 15px);
	overflow-x: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
	scroll-snap-points-y: repeat(100vh);
}

.scroll_down {
    @include media(mobile) {display: none;}
    width: 30px;
    height: 60px;
    position: absolute;
    bottom: 30px;
    left: 155px;
    background-image: url("../img/scroll-down.png");
    cursor: pointer;
}

.first_frame{
  @include media(mobile) {
    position: relative;
    height: calc(100vh - 15px);
    height: 100vh;
    &::before{
      content: '';
      position: absolute;
      background-image: url('../img/bg.png');
      background-size: 95% auto;
      background-repeat: no-repeat;
      background-position: center bottom;
      top: 0;
      left: -30px;
      width: calc(100% + 60px);
      height: 100%;
    }
    .intro{
      position: relative;
      font-size: 14px;
      top: -15px;
      text-shadow: 0 0 10px rgba(0,0,0,0.3);
      color: $black;
    }
    .cta_buttons{
      position: absolute;
      bottom: 15px;
      height: 180px;
      width: 100%;
      display: block;
      .wow{
        visibility: visible!important;
      }
    }
  }
  .contact_details{
    @include media(tablet) {display: none!important;}
  }
}