// @font-face {
// 			font-family: 'Peacock_typo';
//   		src: url('fonts/built_titling_sb.woff2') format('woff2'),
//  			url('fonts/built_titling_sb.woff') format('woff');
// }


@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@font-face { font-family: 'Journal'; src: url("../fonts/journal/journal-webfont.eot"); src: url("../fonts/journal/journal-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/journal/journal-webfont.woff") format("woff"), url("../fonts/journal/journal-webfont.ttf") format("truetype"), url("../fonts/journal/journal-webfont.svg#Journal") format("svg"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'TisaOT';  src: url("../fonts/tisa/TisaOT.eot");  src: url("../fonts/tisa/TisaOT.eot?#iefix")  format("embedded-opentype"),  url("../fonts/tisa/TisaOT.woff") format("woff"), url("../fonts/tisa/TisaOT.svg#TisaOT") format("svg"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'TisaOT'; src: url("../fonts/tisa/TisaOT-Ita.eot"); src: url("../fonts/tisa/TisaOT-Ita.eot?#iefix") format("embedded-opentype"), url("../fonts/tisa/TisaOT-Ita.woff") format("woff"), url("../fonts/tisa/TisaOT-Ita.svg#TisaOT-Ita") format("svg"); font-weight: normal; font-style: italic; }
@font-face { font-family: 'TisaOT-med'; src: url("../fonts/tisa/TisaOT-Medi.eot"); src: url("../fonts/tisa/TisaOT-Medi.eot?#iefix") format("embedded-opentype"), url("../fonts/tisa/TisaOT-Medi.woff") format("woff"), url("../fonts/tisa/TisaOT-Medi.svg#TisaOT-Medi") format("svg"); font-weight: bold; font-style: normal; }
@font-face { font-family: 'TisaOT-med'; src: url("../fonts/tisa/TisaOT-MediIta.eot"); src: url("../fonts/tisa/TisaOT-MediIta.eot?#iefix") format("embedded-opentype"), url("../fonts/tisa/TisaOT-MediIta.woff") format("woff"), url("../fonts/tisa/TisaOT-MediIta.svg#TisaOT-MediIta") format("svg"); font-style: bold; font-weight: italic; }

//setting font-variables
$font_sans: 'Open Sans', sans-serif;
$journal: 'Journal', sans-serif;
$tisaot: 'TisaOT', serif;
