header{
  @include media(mobile) {height: 100px;}
  @include media(tablet) {height: 140px;}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient($custom 30%, $transparent);
  z-index: 99;
  .logo{
    @include media(mobile) {
      box-shadow: 0 0 25px rgba(0,0,0,0.3);    
      left: 0;
      width: 100%;
      height: 100px;
      padding: 10px;
    }
    @include media(tablet) {
      left: calc(55% - 155px);
      width: 310px;
      height: 140px;
      padding: 25px;
    }
    @include media(large) {
      left: calc(50% - 155px);
      width: 310px;
    }
    background-color: $blue;
    position: absolute;
    text-align: center;
    img{
      height: 100%;
    }
  }
}