.navbar-default {
	background-color: transparent !important;
	border-color: transparent !important;
}
.navbar-default .navbar-nav>li>a {
	color: #fff;
	text-transform: uppercase;
}
.navbar-default .navbar-brand {
	color: #fff;
	text-transform: uppercase;
	font-weight: 900;
}
.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
	color: #fff;
	background-color: transparent;
}
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
	color: #fff;
	background-color: none;
}
.carousel-caption {
	padding-bottom: 230px;
}
.carousel-caption h2 {
	font-size: 50px;
	font-family: impact;
	text-transform: uppercase;
	letter-spacing: 2px;
}
.carousel-caption p {
	font-size: 18px;
	width: 70%;
	margin: 20px auto;
	line-height: 1.9;
}
.carousel-caption a {
	background: deepskyblue;
	color: #fff;
	padding: 10px 25px;
	display: inline-block;
	border-radius: 50px;
	text-transform: uppercase;
}
.carousel .carousel-control {
	bottom: auto;
	width: 100%;
	height: 15%;
	background: none;
}
.carousel .carousel-control.right {
	top: auto;
	bottom: 0;
	background: none;
}
.carousel .carousel-indicators {
	bottom: auto;
	top: 50%;
	left: auto;
	right: 10px;
	width: 14px;
	margin: 0;
	transform: translateY(-50%);
}
.carousel .carousel-inner>.item {
	left: 0;
	top: 0;
}
.carousel .carousel-inner>.item>img {
	width: 100%;
}
.carousel .carousel-inner>.item.next, .carousel .carousel-inner>.item.active.right {
	transform: translate3d(0, 100%, 0);
	top: 0;
}
.carousel .carousel-inner>.item.prev, .carousel .carousel-inner>.item.active.left {
	transform: translate3d(0, -100%, 0);
	top: 0;
}
.carousel .carousel-inner>.item.next.left, .carousel .carousel-inner>.item.prev.right, .carousel .carousel-inner>.item.active {
	transform: translate3d(0, 0, 0);
	top: 0;
}
.carousel .carousel-inner>.active, .carousel .carousel-inner>.next.left, .carousel .carousel-inner .prev.right {
	top: 0;
}
.carousel .carousel-inner>.next, .carousel .carousel-inner>.active.right {
	top: 100%;
	left: 0;
}
.carousel .carousel-inner>.prev, .carousel .carousel-inner>.active.left {
	top: -100%;
	left: 0;
}
#carousel-example-generic {
	height: 100vh;
	width: 100%;
}
.carousel .carousel-inner>.item>img {
	width: 100%;
	height: 100vh;
}
@media only screen and (max-width: 991px) {
	.carousel-caption {
		padding-bottom: 340px;
	}
	.carousel-caption h2 {
		font-size: 40px;
		letter-spacing: 0;
	}
}
@media only screen and (max-width: 767px) {
	.navbar-default .navbar-collapse {
		border-color: transparent;
		background: #fff;
	}
	.navbar-default .navbar-nav>li>a {
		color: #000;
	}
	.carousel-caption p {
		font-size: 12px;
		width: 95%;
		margin: 10px auto;
	}
	.carousel-caption {
		padding-bottom: 120px;
	}
	.carousel-caption h2 {
		font-size: 20px;
		letter-spacing: 0;
	}
}