@mixin media($size) {
	  @if $size == mobile {
	    @media (max-width: 719px) { @content; }
	  } @else if $size == tablet {
	    @media (min-width: 720px) { @content; }
	  } @else if $size == desktop {
			@media (min-width: 992px) { @content; }
		} @else if $size == large {
			@media (min-width: 1200px) { @content; }
		}
}
