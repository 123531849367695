footer {
	@include media(mobile) {
		width: 100vw; 
		padding: 30px 15px;
		// height: 135px;
		text-align: center;
		background-color: $white;
		font-size: 14px;
		// position: relative;
		// display: block;
	}
	@include media(tablet) {
		width: calc(45% + 155px); 
		height: 55px; 
		padding: 25px 15px 0 0;
		text-align: right;
		position: fixed;
		bottom: 0;
		right: 0;
		background-image: linear-gradient($transparent, $custom);
		font-size: 13px;
	}
	@include media(large) {
		width: calc(50% + 155px);
	}
	display: block;
	z-index: 9;
	// border: 1px solid red;
	
	.element {
		@include media(mobile) {display: block; line-height: 25px;}
		@include media(tablet) {display: inline-block; line-height: 20px; margin: 0 7px;}
	}
	.footer_line {
		@include media(mobile) {display: none;}
		@include media(tablet) {display: inline-block;}
	}
}
.copyrights {
}
