$black:     #000000;
$darkgrey:  rgba(28,28,28,1);
$lightblack:rgba(14,14,14,1);
$lightgrey: rgba(201,201,201,1);
$grey:      rgba(128,128,128,1);
$grey_lighter: rgb(179, 179, 179);
$shadowtext: rgba(36,36,36,1);
$white: #ffffff;
$bg_cover: rgba(140, 140, 140, 0.8);
$transparent: rgba(0,0,0,0);

$cookie_bg: rgba(16,16,132,0.65);
$time: 0.4s;
$fast: 0.2s;

$custom: rgb(223,214,203);
$custom_dark: rgb(209, 196, 178);
$blue: rgb(0,98,167);
$blue_dark: rgb(0, 75, 128);
$blue_light: rgb(0,121,206);
$txt_color: rgb(62, 62, 62);
$txt_color_light: rgb(75, 75, 75);