.menu{
  position: fixed;
  left: 100vw;
  height: 100vh;
  width: 350px;
  background-color: $white;
  display: flex;
  align-items: center;
  font-family: $tisaot;
  padding-left: 60px; 
  z-index: 9999;
  transition: $fast;
  ul, li{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li{margin-bottom: 24px; }
  .mmlink{
    color: rgb(138, 123, 101);
    font-size: 20px;
    display: block;
    font-weight: 400;
    line-height: 32px;
    &:hover{
      color: rgb(190,181,166);
    }
  }
  .sub_menu{
    padding-top: 5px;
    padding-left: 5px;
    li{margin-bottom: 6px;}
    a:hover{
    text-decoration: underline!important;
    }
  }
}

.open_layer{
  left: 0vw!important;
}
.open_menu{
  transition: $fast;
  left: calc(100vw - 350px)!important;
  right: auto!important;
  box-shadow: 0 0 50px rgba(0,0,0,0.3);
}
.open_menu_icon{
  @include media(mobile) {
    right: 5px!important;
    top: 5px!important;
    .bar{background: $blue!important;}
    .bar2 {width: 0!important; height: 0!important;  transition-property: none!important; }
    .bar1 {
      rotate: 45deg;
      transform-origin: top;
      transform-origin: 8px 10px;
    }
    .bar3 {
      rotate: -45deg;
      transform-origin: 15px -2px;
    }
  }
  @include media(tablet) {
    right: 333px!important;
    padding-left: 20px!important;
    .bar2 {width: 0!important; height: 0!important;  transition-property: none!important; }
    .bar1 {
      rotate: 45deg;
      transform-origin: top;
      width: 20px!important;
      transform-origin: 8px;
    }
    .bar3 {
      rotate: -45deg;
      width: 20px!important;
      transform-origin: 15px 5px;
    }
  }
}

.menu_icon{
  @include media(mobile) {background: $transparent; right: 5px; z-index: 99999;}
  @include media(tablet) {background: $white; right: 0px; z-index: 9998;}
  position: absolute;
  float: right;
  top: 0;
  width: 60px;
  height: 60px;
  padding: 15px;
  cursor: pointer;
  transition: $fast;
  .bar{
    @include media(mobile) {background: $white;}
    @include media(tablet) {background: $blue;}
    transition: $fast;
    width: 30px;
    height: 4px;
    margin-top: 5px;
    position: relative;
    border-radius: 1px;
  }
  .bar2 {transition-property: none!important; }
}

.menu_layer{
  position: fixed;
  top: 0;
  left: 100vw;
  height: 100vh;
  width: 100vw;
  z-index: 9;
  background-color: rgba(0,0,0,0.12);
}
