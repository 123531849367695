h1 {
  font-family: $tisaot;
  color: $blue;
  position: relative;
  margin-bottom: 35px;
  font-weight: normal!important;
  @include media(mobile) {font-size: 26px!important; line-height: 40px; margin-top: 30px; letter-spacing: -1px;}
  @include media(tablet) {font-size: 50px!important; line-height: 54px; margin-top: 50px;}
  @include media(desktop) {font-size: 60px!important; line-height: 64px; margin-top: 60px;}
}
h2 {
  margin-top: 45px;
  font-family: $tisaot;
  color: $blue;
  position: relative;
  @include media(mobile) {font-size: 26px!important; line-height: 38px;}
  @include media(tablet) {font-size: 35px!important; line-height: 42px;}
  @include media(desktop) {font-size: 40px!important; line-height: 48px;}
}
h3 {
  font-family: $tisaot;
  font-style: italic;
  color: $blue;
  text-align: center;
  @include media(mobile) {font-size: 23px!important; }
  @include media(tablet) {font-size: 30px!important; }
  @include media(desktop) {font-size: 35px!important; }
}
h4 {
  font-family: $tisaot;
  color: $blue;
  @include media(mobile) {font-size: 22px!important; }
  @include media(tablet) {font-size: 26px!important; }
  @include media(desktop) {font-size: 28px!important; }
}
h5{
  font-family: $tisaot;
  font-style: italic;
  color: $blue;
  @include media(desktop) {font-size: 18px!important; }
}