.cookie_box{
  position: fixed;
  width: 100%;
  background-color: $cookie_bg;
  display: block;
  z-index: 999999;
  right: 0;
  transition: $time;
  color: $lightgrey;
  @include media(mobile) {
    top: calc(100% - 140px);
    height: 140px;
    line-height: 25px;
    padding: 10px;
    text-align: center!important;
    .right {
      float: none!important;
      margin-top: 15px;
    }
  }
.right {
  float: right;
}
  @include media(tablet) {
    top: calc(100% - 60px);
    height: 60px;
    padding: 0px 20px;
    line-height: 60px;
  }
  .box_row{
    @include media(mobile) {display: block;}
    @include media(tablet) {display: inline-flex;}
  }
}
.button_cookie {
	padding: 0px 20px;
	border-radius: 19px;
  text-transform: uppercase;
	font-family: 'Roboto', sans-serif;
	color: $lightgrey;
	line-height: 36px;
	font-size: 13px;
	display: inline-block;
	margin: 0px 6px;
	height: 36px;
  transition: $time;
  cursor: pointer;
}
